@import "src/components/style/foundation/spacing";
@import "src/components/style/foundation/colors";

.team {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

  .teamMember {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 230px;
    border-radius: 10px;
    // from card
    padding: spacing(loose);
    border: 1px solid color(gray, lighter);
    box-shadow: 0px 0px 10px color(gray, lighter);
    margin-bottom: spacing(loose);
    :global(.theme--dark) & {
      border-color: color(gray, darker);
      box-shadow: 0px 0px 10px color(gray, darker);
    }
    // From conference item
    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }
    :global(.theme--dark) &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 10px;

      .avatarImg {
        width: 100%;
        height: auto;
      }
    }
    .designation,
    .role,
    .githubUsername {
      font-size: 14px;
      margin: 5px 0;
    }

    .designation {
      color: #666;
    }

    .role {
      color: #c8c7c7;
    }
    .githubUsername {
      color: #c8c7c7;
      font-size: small;
    }
  }

  .links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    .link_item {
      padding: 5px 10px;
      gap: 5px;
    }
    .contributionContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: color(gray);

      :global(.theme--dark) & {
        color: color(gray, lighter);
      }
    }
    .contribution {
      font-size: x-small;
      color: color(gray, base);
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;

      .icon {
        margin-right: 8px;
        display: flex;
        align-items: center;

        svg {
          fill: currentColor;
        }
      }
    }
  }
}
