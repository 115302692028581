@import 'src/components/style/foundation/spacing';
@import 'src/components/style/foundation/typography';
@import 'src/components/style/foundation/colors';

.Search {
  margin-bottom: spacing();
}

:global {
  .ais-SearchBox-form {
    display: flex;
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }

  .theme--dark .ais-SearchBox-input {
    background-color: rgb(32, 35, 39);
    border-color: transparent;
  }

  .theme--dark .ais-SearchBox-input:focus {
    background-color: black;
  }
}
