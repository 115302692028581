@import 'src/components/style/foundation/mixins';
@import 'src/components/style/foundation/typography';
@import 'src/components/style/foundation/colors';
@import 'src/components/style/foundation/spacing';
@import 'src/components/style/foundation/media-queries';

.Loader {
  text-align: center;
}

.Header {
  padding: spacing(base);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.HeaderLinks {
  margin: 0 0 spacing() 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media($media-query--mobile-up) {
    flex-direction: row;
  }

  a {
    display: inline-block;
    margin: 0 0 spacing(tight) 0;
    text-align: center;

    @include media($media-query--mobile-up) {
      margin: 0 spacing(loose) 0 0;
      display: inline;
    }
  }
}

.RefinementsWrapper {
  padding: spacing(base);
}

.CurrentRefinements {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HitsCount {
  font-size: font-size(body, smaller);
  margin: 0;
}

.LinkGroup button,
.LinkGroup a {
  margin: 0 spacing(loose) 0 0;
}

.OnlineRadios {
  margin-bottom: spacing(base);
  display: flex;

  input {
    width: auto;
  }

  label {
    margin-right: spacing(loose);
    cursor: pointer;
  }

  input {
    margin: 0 spacing(tight) 0 0;
  }

  label {
    display: flex;
    align-items: center;
  }

  @include media($media-query--mobile--vertical) {
    flex-direction: column;

    label {
      margin-bottom: spacing(extra-tight);
    }
  }
}
