@import 'src/components/style/foundation/media-queries';
@import 'src/components/style/foundation/mixins';
@import 'src/components/style/foundation/spacing';
@import 'src/components/style/foundation/typography';
@import 'src/components/style/foundation/colors';

.Heading {
  font-weight: bold;

  &,
  & a {
    font-family: font-family(heading);
  }
}

.Inner {
  display: inline-block;
  position: relative;
}

.Heading-1 {
  font-weight: normal;
  margin: spacing(extra-loose) 0 0 0;
  position: relative;
  font-size: font-size(heading, h1-mobile);

  @include media($media-query--mobile-up) {
    font-size: font-size(heading, h1);
  }

  .Inner {
    @include underlined();

    &::after {
      bottom: 6px;

      :global(.theme--dark) & {
        height: 5px;
        bottom: 6px;
      }
    }
  }
}

.Heading-sub {
  font-size: 1em;
  font-weight: normal;
  font-family: font-family(base);
  color: color(gray);
  margin: 0 0 spacing(tight) 0;

  :global(.theme--dark) & {
    color: color(gray, baseDark);
  }

  @include media($media-query--tablet-up) {
    margin: 0 0 spacing(extra-loose) 0;
  }
}

.Heading-2 {
  font-size: font-size(heading, h2);
}

.Heading-3 {
  font-size: font-size(heading, h3);
  margin-top: spacing(extra-loose);
  margin-bottom: spacing(loose);

  .Inner {
    @include underlined();
  }
}

.Heading-4 {
  margin-top: 0;
  margin-bottom: spacing(extra-tight);
}

