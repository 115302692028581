@import 'src/components/style/foundation/colors';

@mixin link() {
  color: color(accent);
  font-family: font-family(base);
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  border: none;
  padding: 0;
  font-size: font-size(body);
  background: none;

  :global(.theme--dark) & {
    color: color(accent, dark);
  }
}

@mixin link--hover() {
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    background: color(accent-2);
  }
}

@mixin underlined() {
  z-index: 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 8px;
    background: color(accent-2);

    :global(.theme--dark) & {
      height: 4px;
      bottom: 4px;
    }
  }
}
