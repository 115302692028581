@import 'src/components/style/foundation/spacing';
@import 'src/components/style/foundation/typography';
@import 'src/components/style/foundation/colors';

.Footer {
  padding: spacing(extra-loose) spacing(base) 0 spacing(base);
  color: color(gray);
  margin-bottom: spacing(base);
  line-height: line-height(footer);

  &, a {
    font-size: font-size(footer);
  }
}

.FooterLinks a {
  margin: 0 spacing(loose) 0 0;
}
