@import 'src/components/style/foundation/mixins';
@import 'src/components/style/foundation/typography';
@import 'src/components/style/foundation/colors';

.Link {
  @include link();

  &:hover,
  &.Selected {
    @include link--hover();
  }
}

.unstyled {
  text-decoration: none;
}

.muted {
  color: color(gray);

  :global(.theme--dark) & {
    color: color(gray);
  }
}
