@import 'src/components/style/foundation/spacing';
@import 'src/components/style/foundation/colors';

.ConferenceItem {
  margin: 0 -1em spacing(extra-tight) -1em;
  padding: 1em;
  border-radius: 10px;

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }

  :global(.theme--dark) &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

.Date {
  margin-right: spacing(tight);
}

.p {
  margin: 0;
  line-height: 1.5em;
}

.Footer,
.Footer a {
  font-weight: 200;
  color: color(gray);
}

.topics {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.topic {
  margin-right: spacing(extra-tight);
  vertical-align: middle;
  &:last-child {
    margin-right: 0;
  }
}

.dl {
  margin: 0;
  line-height: 1.5;
  &::after {
    content: "";
    clear: both;
    display: table;
  }

  div {
    float: left;
  }
  dd {
    margin-left: 0;
  }
}

.muted {
  color: color(gray);
}
