@import 'src/components/style/foundation/typography';
@import 'src/components/style/foundation/spacing';

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Content {
  padding: spacing();
}

.narrow {
  max-width: 600px;
  padding: spacing();

  p,
  ul,
  p a {
    font-size: font-size(page);
    line-height: line-height(page);
  }
}

