@import 'src/components/style/foundation/typography';
@import 'src/components/style/foundation/colors';
@import 'src/components/style/foundation/spacing';

.Button {
  font-size: font-size(body);
  font-family: inherit;
  padding: spacing(tight) spacing(base);
  display: block;
  border-radius: 3px;
  margin-top: spacing(loose);
  margin-bottom: spacing(extra-loose);
  transition: all 150ms ease-out;
  cursor: pointer;
  border: 1px solid color(grey, light);
  background-color: white;
  color: black;

  :global(.theme--dark) & {
    background-color: color(grey, dark);
    border-color: color(grey, dark);
    color: white;
  }

  &:disabled {
    background-color: color(grey, lighter);
    color: color(grey);
    cursor: not-allowed;

    :global(.theme--dark) & {
      color: color(grey, light);
      background-color: color(grey, dark);
    }
  }

  &:not([disabled]):hover {
    background-color: color(grey, lighter);

    :global(.theme--dark) & {
      background-color: color(grey);
    }
  }

  &:not([disabled]):active {
    background-color: color(grey, light);

    :global(.theme--dark) & {
      background-color: color(grey, light);
      color:  color(grey);
    }
  }
}

:global(.g-recaptcha) {
  margin-top: spacing();
}

.error {
  border-color: red;
  color: red;
}

.errorText {
  color: #ff0000;
}

:global(.react-datepicker-wrapper) {
  display: block;
}

:global(.react-datepicker__input-container) {
  display: block;
}

.InputHint {
  font-size: font-size(body, smaller);
  color: color(gray, base);
}

.Select {
  :global(.theme--dark) & :not(label) {
    color: black;
  }
}
