@import 'src/components/style/foundation/typography';
@import 'src/components/style/foundation/colors';
@import 'src/components/style/foundation/spacing';

:global {
  .ais-CurrentRefinements-list {
    padding-left: 0;
    margin: 0;
    background-color: transparent;
    display: block;
  }

  .ais-CurrentRefinements-item {
    list-style: none;
    display: inline-block;
  }

  .ais-CurrentRefinements-list .ais-CurrentRefinements-category {
    display: inline-block;
    margin-right: spacing(base);
    margin: spacing(extra-tight) spacing(base) spacing(extra-tight) 0;
    background-color: color(gray, lighter);
    color: color(gray, dark);
    padding: 0.3em 0.7em;
    border-radius: 20px;
    font-size: 0.8em;
    transition: all 100ms linear;

    :global(.theme--dark) & {
      background-color: color(gray, dark);
      color: white
    }
  }

  .ais-CurrentRefinements-list .ais-CurrentRefinements-category:hover {
    background-color: color(gray);
    color: white;
  }

  .ais-CurrentRefinements-label {
    display: none;
  }

  .ais-CurrentRefinements-categoryLabel,
  .ais-CurrentRefinements-label {
    vertical-align: middle;
    line-height: 1.5;
  }

  .ais-CurrentRefinements-delete {
    vertical-align: middle;
    appearance: none;
    margin-left: spacing(tight);
    background-color: lighten(color(gray), 20);
    border-radius: 30px;
    border: none;
    display: inline-block;
    position: relative;
    height: 15px;
    width: 15px;
    padding: 0;
    font-size: 12px;
    line-height: 12px;
    color: color(gray, darker);
    transition: all 100ms linear;
  }

  .ais-CurrentRefinements-category:hover .ais-CurrentRefinements-delete {
    color: color(gray);
    background-color: white;
  }
}
